//React
import React, { useState } from 'react'

//Gatsby
import { graphql, withPrefix, Link } from 'gatsby'
import Img from 'gatsby-image'

//Packages
import Helmet from 'react-helmet'

//Actions
import { getReopeningQuery } from '../../actions/getReopeningStates'

//Components
// import Layout from '../../components/layout'
// import FeatureMenu from '../../components/marketing/featureMenu'
// import FeatureTitle from '../../components/marketing/featureTitle'
// import FeatureBody from '../../components/marketing/featureBody'
// import DividerMid from '../../components/elements/dividerMid'
// import SearchHeader from '../../components/marketing/calloutSearchHeader'
// import CompareTable from '../../components/marketing/compareTable'
// import CompareItem from '../../components/marketing/compareItem'
import ReopeningScreenSnippet from './reopeningScreenSnippet'
import ReopeningTempSnippet from './reopeningTempSnippet'
import ReopeningSampleSnippet from './reopeningSampleSnippet'
import ReopeningSourcesSnippet from './reopeningSourcesSnippet'

//Icons
import { FaCommentDots, FaRobot, FaShare } from 'react-icons/fa'
import { MdCheck, MdOndemandVideo, MdPhonelinkRing } from 'react-icons/md'

const Features = (props) => {
  const { data } = props
  console.log('data')
  console.log(data)
  const edges = getReopeningQuery()
  // const edges = data.edges.edges
  console.log('edges')
  console.log(edges)
  const emptyQuery = ''
  const [state, setState] = useState({
    filteredData: [],
    targetState: emptyQuery,
    targetVertical: emptyQuery,
  })

  const targetIndustries = [
    { name: 'Agriculture', id: 'agriculture' },
    { name: 'Amusement Parks', id: 'amusementpark' },
    { name: 'Bars', id: 'bar' },
    { name: 'Casinos', id: 'casino' },
    { name: 'Child Care', id: 'daycare' },
    { name: 'Construction', id: 'construction' },
    { name: 'Dental Offices', id: 'dental' },
    { name: 'Entertainment Venues', id: 'entertainment' },
    { name: 'General Business', id: 'other' },
    { name: 'Gyms & Fitness Clubs', id: 'gym' },
    { name: 'Higher Education', id: 'highered' },
    { name: 'Hotels', id: 'hotel' },
    { name: 'Manufacturing', id: 'manufacturing' },
    { name: 'Medical Offices', id: 'medical' },
    { name: 'Offices', id: 'offices' },
    { name: 'Personal Services', id: 'personalservice' },
    { name: 'Real Estate', id: 'realestate' },
    { name: 'Recreational Sports', id: 'recsports' },
    { name: 'Retail', id: 'retail' },
    { name: 'Restaurants', id: 'restaurant' },
  ]
  const handleInputChange = (event, prop) => {
    const query = prop === 'state' ? event.target.value : targetState
    const query2 = prop === 'vertical' ? event.target.value : targetVertical

    console.log('query')
    console.log(query)
    console.log('query2')
    console.log(query2)

    const { data } = props
    // this is how we get all of our posts
    const targetStates = edges || []
    // return all filtered posts
    const filteredData = targetStates.filter((state) => {
      // destructure data from post frontmatter
      const { name, screen, temp } = state.node
      return (
        // standardize data with .toLowerCase()
        // return true if the description, title or tags
        // contains the query string
        name.toLowerCase() === query.toLowerCase()
        // &&
        // screen.vertical.filter((vert) => vert.name === 'gym')
      )
    })
    // update state according to the latest query and results
    setState({
      targetState: query, // with current query string from the `Input` event
      targetVertical: query2,
      filteredData, // with filtered data from posts.filter(post => (//filteredData)) above
    })
  }

  const { filteredData, targetState, targetVertical } = state
  const hasSearchResults = filteredData && targetState !== emptyQuery
  const targetStates = hasSearchResults ? filteredData : []

  console.log('filteredData')
  console.log(filteredData)

  const filteredVerticalScreen =
    filteredData &&
    filteredData[0] &&
    filteredData[0].node &&
    filteredData[0].node.screen &&
    filteredData[0].node.screen.vertical &&
    targetVertical
      ? filteredData[0].node.screen.vertical.filter(
          (vertical) => vertical.name === targetVertical
        )
      : []
  const filteredVerticalTemp =
    filteredData &&
    filteredData[0] &&
    filteredData[0].node &&
    filteredData[0].node.temp &&
    filteredData[0].node.temp.vertical &&
    targetVertical
      ? filteredData[0].node.temp.vertical.filter(
          (vertical) => vertical.name === targetVertical
        )
      : []
  console.log('filteredVerticalScreen')
  console.log(filteredVerticalScreen)

  return (
    <>
      <div class="reopening-search bg-sky-7 border-1-sky-2 rounded-corners p-4 my-2">
        <div class="form-group">
          <label for="step1" class="display-4 black-3 py-1">
            1. What's your state?
          </label>
          <select
            class="form-control"
            id="step1"
            onChange={(e) => {
              handleInputChange(e, 'state')
            }}
          >
            <option>Select state...</option>
            <option value="Alabama">Alabama</option>
            <option value="Alaska">Alaska</option>
            <option value="Arizona">Arizona</option>
            <option value="Arkansas">Arkansas</option>
            <option value="California">California</option>
            <option value="Colorado">Colorado</option>
            <option value="Connecticut">Connecticut</option>
            <option value="Delaware">Delaware</option>
            {/* <option value="District of Columbia">District of Columbia</option> */}
            <option value="Florida">Florida</option>
            <option value="Georgia">Georgia</option>
            {/* <option value="Guam">Guam</option> */}
            <option value="Hawaii">Hawaii</option>
            <option value="Idaho">Idaho</option>
            <option value="Illinois">Illinois</option>
            <option value="Indiana">Indiana</option>
            <option value="Iowa">Iowa</option>
            <option value="Kansas">Kansas</option>
            <option value="Kentucky">Kentucky</option>
            <option value="Louisiana">Louisiana</option>
            <option value="Maine">Maine</option>
            <option value="Maryland">Maryland</option>
            <option value="Massachusetts">Massachusetts</option>
            <option value="Michigan">Michigan</option>
            <option value="Minnesota">Minnesota</option>
            <option value="Mississippi">Mississippi</option>
            <option value="Missouri">Missouri</option>
            <option value="Montana">Montana</option>
            <option value="Nebraska">Nebraska</option>
            <option value="Nevada">Nevada</option>
            <option value="New Hampshire">New Hampshire</option>
            <option value="New Jersey">New Jersey</option>
            <option value="New Mexico">New Mexico</option>
            <option value="New York">New York</option>
            <option value="North Carolina">North Carolina</option>
            <option value="North Dakota">North Dakota</option>
            {/* <option value="Northern Marianas Islands">
              Northern Marianas Islands
            </option> */}
            <option value="Ohio">Ohio</option>
            <option value="Oklahoma">Oklahoma</option>
            <option value="Oregon">Oregon</option>
            <option value="Pennsylvania">Pennsylvania</option>
            {/* <option value="Puerto Rico">Puerto Rico</option> */}
            <option value="Rhode Island">Rhode Island</option>
            <option value="South Carolina">South Carolina</option>
            <option value="South Dakota">South Dakota</option>
            <option value="Tennessee">Tennessee</option>
            <option value="Texas">Texas</option>
            <option value="Utah">Utah</option>
            <option value="Vermont">Vermont</option>
            <option value="Virginia">Virginia</option>
            {/* <option value="Virgin Islands">Virgin Islands</option> */}
            <option value="Washington">Washington</option>
            <option value="West Virginia">West Virginia</option>
            <option value="Wisconsin">Wisconsin</option>
            <option value="Wyoming">Wyoming</option>
          </select>
        </div>
        <div class="form-group">
          <label for="step2" class="display-4 black-3 py-1">
            2. What's your industry?
          </label>
          {/* <select
            class="form-control"
            id="exampleFormControlSelect1"
            onChange={handleInputChange}
          >
            <option>Select industry...</option>
            <option value="Alabama">Alabama</option>
            <option value="Alaska">Alaska</option>
            <option value="3">Three</option>
          </select> */}
          <div class="">
            {targetIndustries.map((industry) => (
              <div class={`d-inline-block`} style={{ margin: 4 }}>
                <label
                  class={`form-check-label rounded-corners p-1 px-2 ${
                    targetVertical === industry.id
                      ? 'border-1-sky-1 bg-blue-1 white-1'
                      : 'border-1-gray-1 bg-white-2 black-4'
                  }`}
                  for={`radio-${industry.id}`}
                >
                  <input
                    class="form-check-input"
                    type="radio"
                    name="reopening-search-radio-industry"
                    id={`radio-${industry.id}`}
                    value={industry.id}
                    onChange={(e) => {
                      handleInputChange(e, 'vertical')
                    }}
                  />
                  <div>
                    <small>{industry.name}</small>
                  </div>
                </label>
              </div>
            ))}
          </div>
          {/* <div class="form-check">
            <label class="form-check-label" for="exampleRadios1">
              <input
                class="form-check-input"
                type="radio"
                name="exampleRadios"
                id="exampleRadios1"
                value="option1"
                checked
              />
            </label>
          </div> */}
          {/* <div class="form-check">
            <label class="form-check-label" for="exampleRadios2">
              <input
                class="form-check-input"
                type="radio"
                name="exampleRadios"
                id="exampleRadios2"
                value="option2"
              />
              <img src="http://placehold.it/40x60/0bf/fff&text=A"></img>
            </label>
          </div> */}
        </div>
      </div>
      {/* <div class="container container-page container-features">
        <div class="row d-flex">
          <div class="col-md-12">
            <select class="w-100 my-1 mr-sm-2" onChange={handleInputChange}>
              <option value="" selected>
                Choose state...
              </option>
            </select>
          </div>
        </div>
      </div> */}
      {/* {targetState && targetVertical
        ? targetStates.map((state) => (
            <>
              <div>{state.node.name}</div>
              <div>{state.node.screen.guide}</div>
              {filteredVerticalScreen && filteredVerticalScreen[0] ? (
                <>
                  <div>{filteredVerticalScreen[0].display}</div>
                  <div>{filteredVerticalScreen[0].guide}</div>
                  <div>
                    {filteredVerticalScreen[0].questions.map((question) => (
                      <div>{question}</div>
                    ))}
                  </div>
                </>
              ) : null}
            </>
          ))
        : null} */}
      <div class="reopening-search-result">
        {targetState && targetVertical && targetStates && targetStates[0] ? (
          <>
            <div class="mt-2"></div>
            <hr size={1} />
            <div class="display-4 mt-1 mb-2">{targetStates[0].node.name}</div>
            <ReopeningScreenSnippet
              state={targetStates[0]}
              vertical={
                filteredVerticalScreen && filteredVerticalScreen[0]
                  ? filteredVerticalScreen[0]
                  : []
              }
            />
            <div class="mt-2"></div>
            <ReopeningTempSnippet
              state={targetStates[0]}
              vertical={
                filteredVerticalTemp && filteredVerticalTemp[0]
                  ? filteredVerticalTemp[0]
                  : []
              }
            />
            <div class="mt-2"></div>
            <ReopeningSampleSnippet state={targetStates[0]} />
            <div class="mt-2"></div>
            <ReopeningSourcesSnippet state={targetStates[0]} />
          </>
        ) : null}
        {/* <ul class="nav nav-pills">
          <li class="nav-item">
            <button type="button" class="nav-link rounded-corners">
              Active
            </button>
          </li>
          <li class="nav-item">
            <button type="button" class="nav-link rounded-corners">
              Link
            </button>
          </li>
          <li class="nav-item">
            <button type="button" class="nav-link rounded-corners">
              Link
            </button>
          </li>
          <li class="nav-item">
            <button type="button" class="nav-link rounded-corners">
              Disabled
            </button>
          </li>
        </ul> */}
      </div>
    </>
  )
}

export default Features

// export const pageQuery = graphql`
//   query BoatQuery4 {
//     allReopening {
//       edges {
//         node {
//           name
//           screen {
//             freq
//             guide
//             questions
//             status
//             vertical {
//               display
//               guide
//               name
//               questions
//             }
//           }
//           sources
//           temp {
//             guide
//             status
//             vertical {
//               display
//               guide
//               name
//             }
//           }
//           date
//         }
//       }
//     }
//   }
// `
