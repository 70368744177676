//React
import React from 'react'

//Components
import ReopeningVerticalSnippetIcon from './reopeningVerticalSnippetIcon'

//Icons
import { FaThermometerThreeQuarters } from 'react-icons/fa'
import { MdAccessTime } from 'react-icons/md'
import { GiHealthNormal } from 'react-icons/gi'
import { FiExternalLink } from 'react-icons/fi'

const Snippet = (props) => {
  const { vertical } = props
  return (
    <>
      {vertical && Object.keys(vertical).length ? (
        <blockquote>
          {vertical.display ? (
            <div class="font-weight-medium">Specific to {vertical.display}</div>
          ) : null}
          <div class="font-weight-light line-height-2 mt-1">
            {vertical.guide}
          </div>
          <div class="font-weight-light line-height-2 mt-1">
            <ul class="mt-1">
              {vertical.questions
                ? vertical.questions.map((question) => <li>{question}</li>)
                : null}
            </ul>
          </div>
        </blockquote>
      ) : null}
    </>
  )
}

export default Snippet
