// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-features-js": () => import("./../src/pages/about/features.js" /* webpackChunkName: "component---src-pages-about-features-js" */),
  "component---src-pages-about-index-js": () => import("./../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-request-access-js": () => import("./../src/pages/request-access.js" /* webpackChunkName: "component---src-pages-request-access-js" */),
  "component---src-pages-request-demo-js": () => import("./../src/pages/request-demo.js" /* webpackChunkName: "component---src-pages-request-demo-js" */),
  "component---src-pages-resources-js": () => import("./../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-unsubscribe-js": () => import("./../src/pages/unsubscribe.js" /* webpackChunkName: "component---src-pages-unsubscribe-js" */),
  "component---src-pages-use-cases-construction-js": () => import("./../src/pages/use-cases/construction.js" /* webpackChunkName: "component---src-pages-use-cases-construction-js" */),
  "component---src-pages-use-cases-gyms-js": () => import("./../src/pages/use-cases/gyms.js" /* webpackChunkName: "component---src-pages-use-cases-gyms-js" */),
  "component---src-pages-use-cases-home-health-js": () => import("./../src/pages/use-cases/home-health.js" /* webpackChunkName: "component---src-pages-use-cases-home-health-js" */),
  "component---src-pages-use-cases-restaurants-js": () => import("./../src/pages/use-cases/restaurants.js" /* webpackChunkName: "component---src-pages-use-cases-restaurants-js" */),
  "component---src-pages-use-cases-retail-js": () => import("./../src/pages/use-cases/retail.js" /* webpackChunkName: "component---src-pages-use-cases-retail-js" */),
  "component---src-pages-use-cases-travel-js": () => import("./../src/pages/use-cases/travel.js" /* webpackChunkName: "component---src-pages-use-cases-travel-js" */),
  "component---src-templates-about-template-js": () => import("./../src/templates/aboutTemplate.js" /* webpackChunkName: "component---src-templates-about-template-js" */),
  "component---src-templates-blog-template-js": () => import("./../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-landing-template-js": () => import("./../src/templates/landingTemplate.js" /* webpackChunkName: "component---src-templates-landing-template-js" */)
}

