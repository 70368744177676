//React
import React from 'react'

//Components
import ReopeningVerticalSnippet from './reopeningVerticalSnippet'

//Icons
import { FaThermometerThreeQuarters } from 'react-icons/fa'
import { MdAccessTime } from 'react-icons/md'
import { GiHealthNormal } from 'react-icons/gi'
import { FiExternalLink } from 'react-icons/fi'

const Snippet = (props) => {
  const { state } = props
  return (
    <>
      {state.node.sources && state.node.sources.length > 0 ? (
        <>
          <span class="gray-1 reopening-tool-col-head">
            Source{state.node.sources.length > 1 ? 's' : ''}
          </span>

          <div class="reopening-tool-sources mt-2">
            <div class="font-weight-light line-height-2">
              <ul class="mt-1">
                {state.node.sources.map((source) => (
                  <li class="d-flex flex-row">
                    <a
                      href={source}
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      {source}
                    </a>{' '}
                    <FiExternalLink
                      size={11}
                      class="ml-1 gray-1"
                      style={{ position: 'relative', bottom: -3 }}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </>
      ) : null}
    </>
  )
}

export default Snippet
