//React
import React, { useState } from 'react'

//Gatsby
import { Link, StaticQuery, graphql } from 'gatsby'

//Packages
import { CopyToClipboard } from 'react-copy-to-clipboard'
import parse from 'url-parse'

//Components
// import FeatureIcon from "./featureIcon"
import ReopeningScreenSnippet from './reopeningScreenSnippet'
import ReopeningTempSnippet from './reopeningTempSnippet'
import ReopeningSourcesSnippet from './reopeningSourcesSnippet'

//Icons
import { BsLink } from 'react-icons/bs'

const Snippet = () => {
  const [copied, setCopied] = useState(false)

  let url
  if (typeof window !== `undefined`) {
    url = parse(window.location.href, true)
  }

  return (
    <StaticQuery
      query={graphql`
        query ReopeningQuery {
          allReopening(sort: { fields: name, order: ASC }) {
            edges {
              node {
                name
                sources
                sample
                screen {
                  status
                  freq
                  guide
                  questions
                  vertical {
                    display
                    guide
                    name
                    questions
                  }
                }
                temp {
                  status
                  guide
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <>
          <div class="container">
            {data.allReopening.edges.map((state) => (
              <>
                <div class="row mt-2">
                  <div class="col-lg-12">
                    <a id={state.node.name.toLowerCase().replace(' ', '')}></a>
                    <div class="d-flex flex-row justify-content-start align-content-end">
                      <h3>{state.node.name}</h3>
                      <CopyToClipboard
                        text={`${url && url.protocol ? url.protocol : ''}//${
                          url && url.host ? url.host : ''
                        }${
                          url && url.pathname ? url.pathname : ''
                        }#${state.node.name.toLowerCase().replace(' ', '')}`}
                        onCopy={() => {
                          setCopied({
                            [state.node.name
                              .toLowerCase()
                              .replace(' ', '')]: true,
                          })
                          setTimeout(() => {
                            setCopied({
                              [state.node.name
                                .toLowerCase()
                                .replace(' ', '')]: false,
                            })
                          }, 3000)
                        }}
                      >
                        <button class="btn btn-link" type="button">
                          <BsLink size={20} class="gray-1" />
                        </button>
                      </CopyToClipboard>
                      {copied &&
                      copied[state.node.name.toLowerCase().replace(' ', '')] ? (
                        <small class="gray-1 d-flex align-items-center">
                          Copied {state.node.name} link to clipboard
                        </small>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6 d-flex flex-column">
                    <ReopeningScreenSnippet state={state} />
                  </div>
                  <div class="col-lg-6 d-flex flex-column">
                    <ReopeningTempSnippet state={state} />
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-lg-12">
                    <ReopeningSourcesSnippet state={state} />
                  </div>
                </div>
                <hr size={1} class="mt-5" />
              </>
            ))}
          </div>
        </>
      )}
    />
  )
}

export default Snippet
