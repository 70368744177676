//React
import React from 'react'

//Components
import ReopeningVerticalSnippet from './reopeningVerticalSnippet'

//Icons
import { FaThermometerThreeQuarters } from 'react-icons/fa'
import { MdAccessTime } from 'react-icons/md'
import { GiHealthNormal } from 'react-icons/gi'
import { FiExternalLink } from 'react-icons/fi'

const Snippet = (props) => {
  const { state, vertical } = props
  return (
    <>
      <div>
        <span class="dkgreen-1 reopening-tool-col-head">
          Temperature screening
        </span>
        <FaThermometerThreeQuarters
          size={16}
          class="dkgreen-1"
          style={{ marginTop: -3 }}
        />
      </div>

      <div class="reopening-tool-temp border-1-dkgreen-2 rounded-corners p-2 pb-3 flex-grow-1">
        <div>
          {/* <strong class="mr-1 font-weight-medium">Guidance:</strong> */}
          <span class="font-weight-bold">{state.node.temp.status}</span>
          <span class="font-weight-light ml-1">
            {state.node.temp.status.includes('No guidance') ? 'from' : 'by'}{' '}
            {state.node.name}
          </span>
        </div>
        {state.node.temp.guide && state.node.temp.guide.length > 0 ? (
          <div class="mt-2">
            <div class="font-weight-medium">General Guidance</div>
            <div class="font-weight-light line-height-2">
              <ul class="mt-1">
                {state.node.temp.guide.map((guide) => (
                  <li>{guide}</li>
                ))}
              </ul>
            </div>
          </div>
        ) : null}
        <ReopeningVerticalSnippet vertical={vertical} />
      </div>
    </>
  )
}

export default Snippet
