//React
import React from 'react'

//Components
import ReopeningVerticalSnippet from './reopeningVerticalSnippet'

//Icons
import { FaThermometerThreeQuarters } from 'react-icons/fa'
import { MdAccessTime } from 'react-icons/md'
import { GiHealthNormal } from 'react-icons/gi'
import { FiExternalLink } from 'react-icons/fi'

const Snippet = (props) => {
  const { state, vertical } = props
  return (
    <>
      <div>
        <span class="dkblue-1 reopening-tool-col-head">Health screening</span>
        <GiHealthNormal
          size={12}
          class="dkblue-1"
          style={{ marginTop: -1, marginLeft: 3 }}
        />
      </div>

      <div class="reopening-tool-screen border-1-dkblue-4 rounded-corners p-2 pb-3 flex-grow-1">
        <div>
          {/* <strong class="mr-1 font-weight-medium">
      Guidance:{' '}
    </strong> */}
          <span class="font-weight-bold">{state.node.screen.status}</span>
          <span class="font-weight-light ml-1">
            {state.node.screen.status.includes('No guidance') ? 'from' : 'by'}{' '}
            {state.node.name}
          </span>{' '}
        </div>
        {state.node.screen.freq ? (
          <div>
            {/* <strong class="mr-1 font-weight-medium">
      Frequency:{' '}
    </strong> */}

            <MdAccessTime
              size={16}
              className="mr-2"
              style={{ marginTop: -4 }}
            />
            <span class="font-weight-light">{state.node.screen.freq}</span>
          </div>
        ) : null}
        {state.node.screen.guide ? (
          <div class="mt-2">
            <div class="font-weight-medium">General Guidance</div>
            <div class="font-weight-light line-height-2">
              {state.node.screen.guide}
            </div>
            {state.node.screen.questions &&
            state.node.screen.questions.length ? (
              <div class="font-weight-light line-height-2">
                <ul class="mt-1">
                  {state.node.screen.questions.map((question) => (
                    <li>{question}</li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        ) : null}
        <ReopeningVerticalSnippet vertical={vertical} />
      </div>
    </>
  )
}

export default Snippet
