//React
import React from 'react'

//Icons
import { FaRegLightbulb } from 'react-icons/fa'
import { MdAccessTime } from 'react-icons/md'
import { GiHealthNormal } from 'react-icons/gi'
import { FiExternalLink } from 'react-icons/fi'

const Snippet = (props) => {
  const { state } = props
  return (
    <>
      {state.node.sample && state.node.sample.length > 0 ? (
        <>
          <div>
            <span class="purple-1 reopening-tool-col-head">
              Question ideas &amp; templates
            </span>
            <FaRegLightbulb
              size={12}
              class="purple-1"
              style={{ marginTop: -1, marginLeft: 3 }}
            />
          </div>

          <div class="reopening-tool-sample border-1-purple-2 rounded-corners p-2 pb-3 flex-grow-1">
            <div class="font-weight-light line-height-2">
              <ul class="mt-1">
                {state.node.sample.map((source) => (
                  <li class="d-flex flex-row">
                    <a
                      href={source}
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      {source}
                    </a>{' '}
                    <FiExternalLink
                      size={11}
                      class="ml-1 black-3"
                      style={{ position: 'relative', bottom: -3 }}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </>
      ) : null}
    </>
  )
}

export default Snippet
