/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

//Bootstrap

// import 'jquery/dist/jquery.min.js'
// import 'bootstrap/dist/js/bootstrap.bundle.min.js'

//Redux
import wrapWithProvider from './wrap-with-provider'
export const wrapRootElement = wrapWithProvider

// export const onClientEntry = () => {
//   // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
//   if (typeof window.IntersectionObserver === `undefined`) {
//     import(`intersection-observer`)
//     console.log(`# IntersectionObserver is polyfilled!`)
//   }
// }
