//React
import { useStaticQuery, graphql } from 'gatsby'

export const getReopeningQuery = () => {
  const { allReopening } = useStaticQuery(
    graphql`
      query SiteMetaData {
        allReopening {
          edges {
            node {
              name
              screen {
                freq
                guide
                questions
                status
                vertical {
                  display
                  guide
                  name
                  questions
                }
              }
              sources
              sample
              temp {
                guide
                status
                vertical {
                  display
                  guide
                  name
                  questions
                }
              }
              date
            }
          }
        }
      }
    `
  )
  console.log('static - allReopening')
  console.log(allReopening)
  return allReopening.edges
}
// export const getReopeningQuery = () => {
//   const { data } = useStaticQuery(
//     graphql`
//       query ReopeningQuery2 {
//         allReopening {
//           edges {
//             node {
//               name
//               screen {
//                 freq
//                 guide
//                 questions
//                 status
//                 vertical {
//                   display
//                   guide
//                   name
//                   questions
//                 }
//               }
//               sources
//               temp {
//                 guide
//                 status
//                 vertical {
//                   display
//                   guide
//                   name
//                 }
//               }
//               date
//             }
//           }
//         }
//       }
//     `
//   )
//   console.log('static - data')
//   console.log(data)
//   return data
// }
